<template>
  <b-form-group :label="label" :label-for="labelFor" :invalid-feedback="invalidFeedback" :disabled="disabled" :class="formGroupClass">
    <b-form-input :id="labelFor" ref="inputRef" :disabled="disabled" :state="state"></b-form-input>
  </b-form-group>

</template>

<script>
/** Docs: https://dm4t2.github.io/vue-currency-input/guide.html **/
import { useCurrencyInput } from 'vue-currency-input'
import { watch } from '@vue/composition-api';
import {debouncedWatch, watchDebounced} from '@vueuse/core'

export default {
  name: 'PaymentAmount',
  props: {
    value: {
      type: Number,
      default: null,
      required: false
    },
    options: {
      type: Object,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    state: {
      type: Boolean,
      default: null
    },
    label: { type: String, default: null},
    labelFor: { type: String, default: null},
    formGroupClass: { type: String, default: null},
    invalidFeedback: {
      type: String,
      default: null
    }

  },
  setup(props, { emit }) {
    const { inputRef, numberValue, setValue } = useCurrencyInput(props.options, false)

    watch(() => props.value, (value) => {
      emit('update', value)
      setValue(value)
    });

    watchDebounced(numberValue, (value) => emit('input', value), { debounce: 500 }) // Vue 2: emit('input', value)

    return { inputRef }
  }
}
</script>

<style scoped>

</style>
