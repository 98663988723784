<template>
  <b-modal :id="id" centered size="xl" title="New Payment" no-close-on-backdrop footer-class="d-block" @hidden="clear()">
    <!-- Content -->
    <template #default>
      <b-container>

        <b-alert show dismissible variant="primary">
          <b-icon-info-circle class="mr-1"/> <small>Only add a received date if you actually received the money. This field is used to calculate the total amount paid.</small>
        </b-alert>

        <validation-observer ref="observer" v-slot="{ handleSubmit }" tag="div">
          <form ref="form" @submit.stop.prevent="handleSubmit(create)">
            <b-row>
              <b-col>
                <validation-provider v-slot="validationContext" vid="payments-type" name="Payment Type" :rules="{ required: true }">
                  <b-form-group label="Type"
                                label-for="type-input"
                                :invalid-feedback="validationContext.errors[0]"
                                :state="getValidationState(validationContext)"
                                class="mb-0">
                    <v-select id="type-input"
                              v-model="payment.type"
                              label="type"
                              :options="options.types"
                              :clearable="false"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              placeholder="Payment Type"
                              class="w-100 font-small-3"/>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col v-if="isCheck">
                <validation-provider v-slot="validationContext" vid="payments-check" name="Check Number" :rules="{ required: false }">
                  <b-form-group label="Check Number"
                                label-for="payment-check-input"
                                :invalid-feedback="validationContext.errors[0]"
                                class="mb-0">
                    <b-form-input id="payment-check-input"
                                  v-model="payment.check"
                                  :state="getValidationState(validationContext)"
                                  placeholder="Check Number"/>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="2">
                <validation-provider v-slot="validationContext" vid="payments-amount" name="Payment Amount" :rules="{ required: true }">
                  <payment-amount v-model="payment.amount"
                                  :options="{currency: 'USD', autoDecimalDigits: false}"
                                  :state="getValidationState(validationContext)"
                                  :invalid-feedback="validationContext.errors[0]"
                                  label="Amount" label-for="payment-amount" form-group-class="mb-0"
                  />
                </validation-provider>
              </b-col>
              <b-col>
                <b-form-group label="Received" label-for="payment-received">
                  <b-input-group>
                    <flat-pickr id="payment-received"
                                ref="payment-received"
                                v-model="payment.received"
                                :config="config.pickr.received"
                                placeholder="MM/DD/YYYY"
                                class="form-control"/>
                    <b-input-group-append>
                      <b-button size="sm" variant="white" @click="$refs['payment-received'].fp.open()">
                        <b-icon-calendar-date/>
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
<!--                  <validation-provider v-slot="validationContext" name="Received" :rules="{ required: false }">
                    <billing-date-picker v-model="payment.received"
                                         label="Received" label-for="payment-received"
                                         :state="getValidationState(validationContext)"
                                         :invalid-feedback="validationContext.errors[0]" :config="{}"/>



&lt;!&ndash;                    <b-form-group label="Received"
                                  label-for="payment-received-input"
                                  :state="getValidationState(validationContext)"
                                  :invalid-feedback="validationContext.errors[0]"
                                  class="mb-0">
                      <b-input-group>
                        <b-form-input id="payment-received-input"
                                      v-model="payment.received"
                                      v-mask="'####-##-##'"
                                      type="text"
                                      placeholder="YYYY-MM-DD">
                        </b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker v-model="payment.received"
                                             button-only
                                             button-variant="primary"
                                             :today-button="true"
                                             right
                                             size="sm"
                                             :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                             locale="en"
                                             :hide-header="true" />

                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>&ndash;&gt;
                  </validation-provider>-->
                </b-form-group>
              </b-col>
            </b-row>
          </form>
        </validation-observer>
      </b-container>
    </template>

    <!-- Footer -->
    <template #modal-footer>
      <b-row>
<!--        <b-col align-self="center">
          <b-checkbox v-model="state.redirect" :checked="true" :unchecked-value="false">
            <small>Redirect to page on submit</small>
          </b-checkbox>
        </b-col>-->
        <b-col align-self="center" class="text-right pr-75">
          <b-button type="reset" class="mr-1" @click="clear()">Clear</b-button>
          <b-button type="submit" variant="primary" @click="$refs.observer.handleSubmit(create)">Submit</b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
  import { API, graphqlOperation } from 'aws-amplify';
  import { uuid } from 'vue-uuid';
  import vSelect from 'vue-select'
  import notify from '@/mixins/notify.mixin'
  import PaymentAmount from '@/components/PaymentAmount.vue';
  import { createPayment } from './invoice-payment-modal';
  import {mask} from 'vue-the-mask'
  import BillingDatePicker from '@/views/management/billing/BillingDatePicker.vue';
  import flatPickr from 'vue-flatpickr-component';

  export default {
    name: 'InvoicePaymentModal',
    components: {
      BillingDatePicker,
      PaymentAmount,
      vSelect,
      flatPickr
    },
    directives: { mask },
    mixins: [ notify ],
    props: {
      id: {
        type: String,
        required: true,
      },
      invoiceId: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        payment: {
          type: null,
          amount: 0,
          check: null,
          received: null
        },
        options: {
          types: [
            'Parent Check',
            'Scholarship',
            'BOCES Check',
            'BOCES P.O.',
            'School Check',
            'School P.O.'
          ]
        },
        config: {
          cleave: { date: true, datePattern: ['m', 'd', 'Y'], delimiter: '/' },
          pickr: {
            received: {
              allowInput: false,
              dateFormat: 'Z',
              altFormat: 'm/d/Y',
              altInput: true,
              altInputClass: this.disableReceived || this.disableAll ? 'form-control rounded-left disabled' : 'form-control rounded-left',
              //minDate: this.payment.createdAt,
              clickOpens: true,
            },
            returned: {
              allowInput: false,
              dateFormat: 'Z',
              altFormat: 'm/d/Y',
              altInputClass: this.disableReturned || this.disableAll ? 'form-control rounded-left disabled' : 'form-control rounded-left',
              altInput: true,
              //minDate: this.payment.received,
              clickOpens: true,
            }
          }
        },
        state: {
          redirect: false
        },
      }
    },
    computed: {
      isCheck() {
        return this.payment.type?.includes('Check') || false
      }
    },
    methods: {
      async create() {
        try {
          const id = uuid.v4();
          const input = {
            id: id,
            type: this.payment.type,
            amount: this.payment.amount,
            check: this.payment.check,
            received: this.payment.received,
            invoicePaymentsId: this.invoiceId,
          }

          const response = await API.graphql(graphqlOperation(createPayment, { input: input }));
          const payment = response.data.createPayment

          this.$emit('created', payment)
          this.$root.$emit('bv::toggle::modal', this.id)
          this.notify({ title: 'Success', text: 'Payment was successfully created', icon: 'fas fa-server', variant: 'success' });
        }
        catch (e) {
          console.error(e)
          this.notify({ title: 'Error', text: 'Payment failed to create', icon: 'fas fa-server', variant: 'danger'});
        }
      },
      clear() {
        this.payment = {
          type: null,
          amount: 0,
          check: null,
          received: null
        }
      },
      getValidationState({ dirty, validated, valid = null }) {
        return dirty || validated ? valid : null;
      },
    }
  }
</script>

<style scoped>

</style>
