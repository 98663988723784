<template>
  <div>
    <h6 class="mb-1">
      {{ heading }}
    </h6>
    <template>
      <p v-if="name" class="mb-25 d-inline-flex font-weight-bold">
        {{ name }}
      </p>
      <p v-else class="mb-25 d-inline-flex font-weight-bold text-danger">
        No School
      </p>
    </template>

    <template v-if="address">
      <p class="card-text mb-25">
        {{ address.line1 }} {{ address.line2 }}
      </p>
      <p class="card-text mb-25">
        {{ address.city }}, {{ address.state }}
        {{ address.zip }}
      </p>
    </template>
    <template v-if="phone">
      <p class="card-text mb-25">
        {{ phone.number }} {{ phone.ext }}
      </p>
    </template>
    <template v-if="email">
      <p class="card-text mb-0">
        {{ email.address }}
      </p>
    </template>

  </div>
</template>

<script>
export default {
  name: 'InvoiceTo',
  props: {
    heading: {
      type: String,
      required: true
    },
    name: {
      type: String,
      default: null
    },
    address: {
      type: [Object],
      default: null
    },
    phone: {
      type: Object,
      default: null
    },
    email: {
      type: Object,
      default: null
    },
  }
}
</script>

<style scoped>

</style>
