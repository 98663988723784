<template>
  <b-form-group :label="label"
                :label-for="labelFor"
                :state="state"
                :invalid-feedback="invalidFeedback"
                class="mb-0">
    <b-input-group>
      <flat-pickr ref="pickr"
                  v-model="model"
                  :config="pickr"
                  :disabled="disabled"
                  placeholder="MM/DD/YYYY"
                  class="form-control rounded-left"
                  @on-change="onDateChange"
                  />
      <b-input-group-append>
        <b-button size="sm" variant="white" :disabled="disabled" @click="$refs.pickr.fp.open()">
          <b-icon-calendar-date/>
        </b-button>
      </b-input-group-append>
    </b-input-group>
  </b-form-group>

</template>

<script>
import Cleave from 'cleave.js';
import flatPickr from 'vue-flatpickr-component';

export default {
  name: 'BillingDatePicker',
  components: {
    flatPickr
  },
  directives: {
    cleave: {
      inserted: (el, binding) => {
        el.cleave = new Cleave(el, binding.value || {})
      },
    }
  },
  props: {
    value: {
      type: [String, Date],
      default: '',
    },
    config: {
      type: Object,
      required: true,
      //source: https://flatpickr.js.org/options/
      //example: { allowInput: true, clickOpens: false, dateFormat: 'm/d/Y', minDate: this.payment.createdAt}
    },
    disabled: {
      type: Boolean,
      default: false
    },
    label: { type: String, default: null},
    labelFor: { type: String, default: null},
    formGroupClass: { type: String, default: null},
    invalidFeedback: {
      type: String,
      default: null
    },
    state: {
      type: Boolean,
      default: null
    },
  },
  data() {
    return {
      model: this.value,
      initialValue: this.value,
      cleave: { date: true, datePattern: ['m', 'd', 'Y'], delimiter: '/' },
      pickr: {
        defaultDate: null,
        allowInput: false,
        dateFormat: 'Z',
        altFormat: 'm/d/Y',
        altInput: true,
        altInputClass: 'form-control rounded-left flatpickr-altInput',
        clickOpens: true,
      },
      rules: {
        regex: /d{2}\/\d{2}\/\d{4}$/
      },
    }
  },
  watch: {
    value(n, o) {
      if(n !== o) {
        this.model = n
        this.initialValue = n
      }
    },
  },
  created() {
    this.pickr = Object.assign(this.pickr, this.config)
  },
  methods: {
    onDateChange(selectedDates, dateStr, instance) {
      const selectedDate = selectedDates[0]
      if(selectedDate) {
        if (this.initialValue !== selectedDate.toISOString()) {
          this.initialValue = selectedDate.toISOString()
          this.$emit('input', selectedDate.toISOString())
        }
      }
      else if(this.initialValue !== null) {
        this.initialValue = null
        this.$emit('input', null)
      }
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/base/bootstrap-extended/_variables.scss';

.input-group:not(:focus-within) .input-group-append .btn {
  border: 1px solid $input-border-color;
  //background: blue
}
.input-group:focus-within .input-group-append .btn {
  border: 1px solid $input-focus-border-color;
  //background: red
}

.input-group-append > button[disabled] {
  background: $input-disabled-bg!important;
  opacity: 1!important;
}
.form-control.disabled {
  background: $input-disabled-bg!important;
}
.rounded-left {
  border-top-left-radius: 0.357rem!important;
  border-bottom-left-radius: 0.357rem!important;
}

.flatpickr-input ~ .form-control[readonly], .flatpickr-altInput[readonly] {
  background-color: inherit;
}

.flatpickr-input ~ .form-control[readonly][disabled], .flatpickr-altInput[readonly][disabled] {
  background: $input-disabled-bg;
}
</style>
