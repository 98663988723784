<template>

  <validation-observer :ref="`observer-payment-${payment.id}`" :key="payment.id" tag="tr" class="b-tr">
    <b-td class="py-75">
      <validation-provider v-slot="validationContext" :vid="`payment-type-${index}`" name="Payment Type" rules="required">
        <b-form-group :label="showLabels ? 'Type' : null"
                      :label-for="`payment-type-input-${index}`"
                      :invalid-feedback="validationContext.errors[0]"
                      :state="getValidationState(validationContext)"
                      class="mb-0">
          <v-select :id="`payment-type-input-${index}`"
                    v-model="payment.type"
                    :disabled="disableType || disableAll"
                    label="type"
                    :options="options.types"
                    :clearable="false"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    placeholder="Payment Type"
                    class="w-100"
                    @input="patchPayment({ id: payment.id, type: $event })"/>
        </b-form-group>
      </validation-provider>
    </b-td>
    <b-td class="py-75">
      <validation-provider v-slot="validationContext" :vid="`payment-check-${index}`" name="Check Number">
        <b-form-group :label="showLabels ? 'Check Number' : null"
                      :label-for="`payment-check-input-${index}`"
                      :invalid-feedback="validationContext.errors[0]"
                      class="mb-0">
          <b-form-input :id="`payment-check-input-${index}`"
                        v-model="payment.check"
                        :debounce="1000"
                        :disabled="disableCheck || disableAll"
                        :formatter="formatter"
                        :state="getValidationState(validationContext)"
                        placeholder="Check Number"
                        @update="patchPayment({ id: payment.id, check: $event })"/>
          <!--|| payment.type.includes('P.O.')-->
        </b-form-group>
      </validation-provider>
    </b-td>
    <b-td class="py-75">
      <validation-provider v-slot="validationContext" :vid="`payment-amount-${index}`" name="Payment Amount" :rules="{ required: true}">
        <payment-amount v-model="payment.amount"
                        :options="{currency: 'USD', autoDecimalDigits: false}"
                        :disabled="disableAmount || disableAll"
                        :state="getValidationState(validationContext)"
                        :invalid-feedback="validationContext.errors[0]"
                        :label="showLabels ? 'Amount' : null" :label-for="`payment-amount-${index}`" form-group-class="mb-0"
                        @update="patchPayment({ id: payment.id, amount: $event })"
        />
      </validation-provider>
    </b-td>
    <b-td class="py-75">
      <validation-provider v-slot="validationContext" :vid="`payment-received-${index}`" name="Received">
        <b-form-group :label="showLabels ? 'Received' : null"
                      :label-for="`payment-received-input-${index}`"
                      :state="getValidationState(validationContext)"
                      :invalid-feedback="validationContext.errors[0]"
                      class="mb-0">
          <b-input-group>
            <flat-pickr :ref="`payment-received-${index}`"
                        v-model="payment.received"
                        :config="config.pickr.received"
                        :disabled="disableReceived || disableAll"
                        placeholder="MM/DD/YYYY"
                        class="form-control"
                        @on-change="onDateChange('received', $event)"/>
            <b-input-group-append>
              <b-button size="sm" variant="white" :disabled="disableReceived || disableAll" @click="$refs[`payment-received-${index}`].fp.open()">
                <b-icon-calendar-date/>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </validation-provider>
    </b-td>
    <b-td class="py-75">
      <validation-provider v-slot="validationContext" :vid="`payment-returned-${index}`" name="Returned">
        <b-form-group :label="showLabels ? 'Returned' : null"
                      :label-for="`payment-returned-input-${index}`"
                      :state="getValidationState(validationContext)"
                      :invalid-feedback="validationContext.errors[0]"
                      class="mb-0">

          <b-input-group>
            <flat-pickr :ref="`payment-returned-${index}`"
                        v-model="payment.returned"
                        :config="config.pickr.returned"
                        :disabled="disableReturned || disableAll"
                        placeholder="MM/DD/YYYY"
                        class="form-control"
                        @on-change="onDateChange('returned', $event)"/>
            <b-input-group-append>
              <b-button size="sm" variant="white" :disabled="disableReturned || disableAll" @click="$refs[`payment-returned-${index}`].fp.open()">
                <b-icon-calendar-date/>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </validation-provider>
    </b-td>
    <b-td class="py-75 px-1 text-right">
      <b-dropdown :id="`payment-row-actions-${index}`" variant="link" no-caret :right="$store.state.appConfig.isRTL" dropleft size="sm"
                  :popper-opts="{ positionFixed: false }">
        <template #button-content>
          <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body"/>
        </template>
        <slot name="dropdown-items"></slot>
      </b-dropdown>
    </b-td>
  </validation-observer>
</template>

<script>
import vSelect from 'vue-select';
import PaymentAmount from '@/components/PaymentAmount.vue';
import {API, graphqlOperation} from 'aws-amplify';
import {updatePayment} from './invoice';
import notify from '@/mixins/notify.mixin';
import flatPickr from 'vue-flatpickr-component';


export default {
  name: 'InvoicePayment',
  components: {
    PaymentAmount,
    vSelect,
    flatPickr
  },
  directives: { },
  mixins: [ notify ],
  props: {
    index: {
      type: Number,
      required: true
    },
    payment: {
      type: Object,
      required: true
    },
    deletable: {
      type: Boolean,
      default: false
    },
    showLabels: {
      type: Boolean,
      default: false
    },
    disableAll: {
      type: Boolean,
      default: false
    },
    disableType: {
      type: Boolean,
      default: false
    },
    disableCheck: {
      type: Boolean,
      default: false
    },
    disableAmount: {
      type: Boolean,
      default: false
    },
    disableReceived: {
      type: Boolean,
      default: false
    },
    disableReturned: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      options: {
        types: [
          'Parent Check',
          'Scholarship',
          'BOCES Check',
          'BOCES P.O.',
          'School Check',
          'School P.O.',
        ]
      },
      rules: {
        date: {
          //regex: /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/
          regex: /d{2}\/\d{2}\/\d{4}$/
        }
      },
      initialValue: {
        received: this.payment.received,
        returned: this.payment.returned
      },
      config: {
        cleave: { date: true, datePattern: ['m', 'd', 'Y'], delimiter: '/' },
        pickr: {
          received: {
            allowInput: false,
            dateFormat: 'Z',
            altFormat: 'm/d/Y',
            altInput: true,
            altInputClass: this.disableReceived || this.disableAll ? 'form-control rounded-left disabled' : 'form-control rounded-left',
            minDate: this.payment.createdAt,
            clickOpens: true,
          },
          returned: {
            allowInput: false,
            dateFormat: 'Z',
            altFormat: 'm/d/Y',
            altInputClass: this.disableReturned || this.disableAll ? 'form-control rounded-left disabled' : 'form-control rounded-left',
            altInput: true,
            minDate: this.payment.received,
            clickOpens: true,
          }
        }
      },
      icon: 'fa-file-invoice-dollar'
    }
  },
  methods: {
    async patchPayment(input) {
      const isValid = await this.$refs[`observer-payment-${this.payment.id}`].validate();
      if (isValid) {
        try {
          await API.graphql(graphqlOperation(updatePayment, { input: input }));
          this.notify({ title: 'Success', text: 'Invoice was successfully updated', icon: this.icon, variant: 'success' });
        }
        catch (error) {
          console.error(error)
          this.notify({ title: 'Error', text: 'Invoice failed to update', icon: this.icon, variant: 'danger'});
        }
      }
      else {
        this.notify({ title: 'Warning', text: 'Invoice will not update. Field validation failed.', icon: this.icon, variant: 'warning'});
      }
    },
    onDateChange(key, selectedDates, dateStr, instance) {
      const selectedDate = selectedDates[0]
      if(selectedDate) {
        if(this.initialValue[key] !== selectedDate.toISOString()) {
          this.initialValue[key] = selectedDate.toISOString()
          this.patchPayment({ id: this.payment.id, [key]: selectedDate.toISOString() })
        }
      }
      else if(this.initialValue[key] !== null) {
        this.initialValue[key] = null
        this.patchPayment({ id: this.payment.id, [key]: null })
      }
    },
    isEmpty(value) {
      return value === null || value.trim() === ''
    },
    formatter(value) {
      if (value === null || value.trim() === '') {
        return null
      }
      return value.trim()
    },
    getValidationState({dirty, validated, valid = null}) {
      if (!this.disabled) {
        return dirty || validated ? valid : null;
      }
      return null;
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/base/bootstrap-extended/_variables.scss';

.input-group:not(:focus-within) .input-group-append .btn {
  border: 1px solid $input-border-color;
  //background: blue
}
.input-group:focus-within .input-group-append .btn {
  border: 1px solid $input-focus-border-color;
  //background: red
}

.input-group-append > button[disabled] {
  background: $input-disabled-bg!important;
  opacity: 1!important;
}

.form-control.disabled {
  background: $input-disabled-bg!important;
}

.rounded-left {
  border-top-left-radius: 0.357rem!important;
  border-bottom-left-radius: 0.357rem!important;
}


</style>

<style scoped>
  .pb-75 {
    padding-bottom: .75rem;
  }
  .px-45 {
    padding-left: .45rem;
    padding-right: .45rem;
  }
</style>
