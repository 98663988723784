<template>
  <div>
    <div class="logo-wrapper">
      <logo />
      <h3 class="text-primary invoice-logo">
        NYSSMA
      </h3>
    </div>
    <b-card-text class="mb-25">
      718 The Plain Road
    </b-card-text>
    <b-card-text class="mb-25">
      Westbury, NY 11590-5931
    </b-card-text>
    <b-card-text class="mb-0">
      (516) 997-7200
    </b-card-text>
    <b-card-text class="mb-0">
      info@nyssma.org
    </b-card-text>
  </div>
</template>

<script>
  import Logo from '@core/layouts/components/Logo.vue'

  export default {
    components: {
      Logo
    },
  }
</script>

<style lang="scss" scoped>
  @import "~@core/scss/base/pages/app-invoice.scss";
</style>
