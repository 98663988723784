
export default {
    filters: {
        currency(value) {
            return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
        },
        date(value) {
            if(value) {
                const date = new Date(value);
                return date.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });
            }
            return null
        },
    },
    methods: {

    },
}
