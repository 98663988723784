// eslint-disable-next-line import/prefer-default-export
export const listStudents = /* GraphQL */ `
    query ListStudents(
        $id: ID
        $filter: ModelStudentFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listStudents(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                studentInvoiceId
                name {
                    first
                    last
                }
            }
            nextToken
        }
    }
`;


export const createPayment = /* GraphQL */ `
    mutation CreatePayment(
        $input: CreatePaymentInput!
        $condition: ModelPaymentConditionInput
    ) {
        createPayment(input: $input, condition: $condition) {
            id
            type
            amount
            check
            received
            returned
            invoicePaymentsId
        }
    }
`;

